<template>
  <div class="container-fluid">
    <!-- Account Summary -->
    <div class="row">
      <div class="col-12" style="margin-bottom: 20px">
        <md-card>
          <md-body>
            <div style="padding: 10px 20px">
              <h2 style="text-transform: capitalize; color: tomato">
                Welcome, {{ user.firstName }} {{ user.lastName }}!
              </h2>
              {{ this.$store.state.currentUser.balance }}
              <h5 style="font-weight: normal">
                Welcome to Assets Safe versions 3, New wallets receiving Bitcoin
                from another wallet must have a minimum deposit of at least
                <span style="font-size: 14px; font-weight: bold">$300 - $500</span>
              </h5>
              <br />
            </div>
          </md-body>
        </md-card>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12" v-for="card in cards" v-bind:key="card.desc">
        <md-card v-bind:class="[card.color, style]" style="transition: all 500ms ease" md-with-hover>
          <md-card-header>
            <md-card-header-text style="height: 100px">
              <div class="md-title">{{ card.data() | money }}</div>
              <div class="md-subhead">{{ card.desc }}</div>
              <md-button v-if="card.canWithdraw" @click="gotoWithdraw" style="color: #055cb3"
                class="md-raised md-asset">Withdraw</md-button>
              <md-button v-if="card.canDeposit" @click="stepOne" style="color: #055cb3"
                class="md-raised md-asset">Deposit</md-button>
            </md-card-header-text>

            <md-card-media>
              <md-icon class="md-size-3x">{{ card.icon }}</md-icon>
            </md-card-media>
          </md-card-header>
        </md-card>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div id="coinmarketcap-widget-marquee" coins="1,1027,825,74,7083,1839,6138,6892,1697" currency="USD" theme="light"
      transparent="true" show-symbol-logo="true"></div>
    <div class="row">
      <div class="col-sm-12">
        <iframe scrolling="no" allowtransparency="true" frameborder="0"
          src="https://dashboard-assetsafe-ad451.web.app/cart.html" title="Charts" style="
                                                                    box-sizing: border-box;
                                                                    height: 600px !important;
                                                                    width: 100%;
                                                                    margin: 0px !important;
                                                                  "></iframe>
      </div>
    </div>
    <md-dialog :md-active.sync="showWelcome">
      <md-content class="md-scrollbar">
        <section class="">
          <div style="padding: 0px 20px">
            <h2 style="text-transform: capitalize">
              Welcome, {{ user.firstName }} {{ user.lastName }}!
            </h2>

            <h5 style="font-weight: normal">
              Welcome to Assets Safe versions 3, New wallets receiving Bitcoin
              from another wallet must have a minimum deposit of at least
              <span style="font-size: 14px; font-weight: bold">$300 - $500</span>
            </h5>
            <br />
            <div style="display: flex; justify-content: center">
              <md-button class="" @click="showWelcome = false">Close</md-button>
              <md-button class="md-raised md-primary" style="color: #fff" @click="noLongerFirstTime()">Proceed</md-button>
            </div>
          </div>
        </section>
      </md-content>
    </md-dialog>
    <md-dialog :md-click-outside-to-close="false" :md-fullscreen="false" :md-theme="light" :md-active.sync="showDialog"
      style="color:#000">
      <!-- STEP ONE  1 -->
      <!-- Enter Amount Card -->
      <md-card v-if="step === 1">
        <md-card-header>

        </md-card-header>

        <md-card-content>
          <p>Enter how much you want to invest in USD ($)</p>
          <!--Enter Amount -->
          <div class="form-group">
            <input v-model="$v.amount.$model" class="form-control" placeholder="Enter Amount" type="number" />

          </div>
        </md-card-content>

        <md-card-actions>
          <!-- CANCEL -->
          <md-button @click="cancel()">Cancel</md-button>
          <!-- PROGRESS BAR -->
          <md-progress-spinner v-if="isLoading" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
          <!-- SUBMIT -->
          <md-button style="color: #fff" @click="stepTwoA" :disabled="amtFormValid"
            class="md-dense md-raised md-primary">Proceed</md-button>
        </md-card-actions>
      </md-card>

      <!-- STEP TWO-->

      <div v-if="step === 2" style="padding: 40px; overflow: auto">
        <md-content class="md-scrollbar">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="mdl-card__title-text mdl-typography--font-bold">
                How do you intend to deposit
              </h2>
              <p>Select your method of deposit</p>
              <br />

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <br />

              <div style="display: flex; width:100%;  justify-content: space-around;align-content:center;">
                <img @click="stepTwoB('btc')" src='btc.png' width="100" height="100" />
                <img @click="stepTwoB('eth')" src='eth.png' width="100" height="100" />
              </div>



            </div>
          </div>
        </md-content>
      </div>

      <!-- STEP ONE  3 -->
      <!-- ACCEPT TERMS AND CONDITION  -->

      <div v-if="step === 3" style="padding: 20px; overflow: auto">
        <md-content class="md-scrollbar">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="mdl-card__title-text mdl-typography--font-bold">
                Total: <b>{{ cryptoAmt }}</b> {{ coinSeleted.toUpperCase() }}
              </h2>
              <br />
              <p class="">
                1. Go to any bitcoin ATM near you or go to blockchain.info and get {{ cryptoNamePlural }} ({{
                  coinSeleted.toUpperCase() }}) if you don't
                have it.
              </p>
              <p>2. Correctly scan or copy the wallet address to avoid loss transaction</p>
              <p>
                3. Send <b>{{ cryptoAmt }}</b> {{ cryptoNamePlural }} to the
                address below.
              </p>
              <p>
                You can reach our to our support team for assistance if needed
              </p>
              <p>
                <b>Send exactly <b>{{ cryptoAmt }}</b> {{ coinSeleted.toUpperCase() }} (plus miner fee) to:</b>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <br />

              <center>
                <img :src="'./wallet.jpg'" width="200" height="200" />
              </center>
              <p style="
                                      color: #eb4c22;
                                      text-align: center;
                                      font-weight: bolder;
                                      text-overflow: string;
                                    ">
                {{ 'bc1q5cukj0phavdkqtmdmm447ypwvtarhhp8d9gypw' }}
              </p>

              <br />
              <br />

              <center>
                <button v-if="!isLoading" @click.prevent="stepThree()" class="btn btn-primary">
                  I have sent {{ btc }} to the above address
                </button>

                <md-progress-spinner v-if="isLoading" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
              </center>

              <center>
                <md-button v-if="!isLoading" style="margin:10px" @click.prevent="showDialog = false" class="btn">
                  Cancel
                </md-button>
              </center>
            </div>
          </div>
        </md-content>
      </div>
    </md-dialog>
  </div>
</template>

<style scoped>
.green {
  background: #43a047 !important;
}

.blue {
  background: #1e88e5 !important;
}

.btnHover:hover {
  -webkit-transform: scale(1.05) !important;
  -ms-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}
</style>

<script>
// import Http from "../../helpers/http";
import UTILS from "../../helpers/utils";
import { required } from "vuelidate/lib/validators";
import Http from "../../helpers/http";
import { HOST } from "../../store/global";

const between = function (val) {
  const min = 300;
  const max = 1000000;
  const amt = Number(`${val}`);

  return amt > min - 1 && amt < max - 1;
};
export default {
  name: "Dashboard",
  filters: {
    money: function (value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), "") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },

  },
  data() {
    return {
      style:
        "animate__animated animate__slow animate__bounce md-elevation-10 btnHover",
      showWelcome: false,
      user: this.$store.state.currentUser,
      cards: [
        {
          title: "0.00",
          desc: "Wallet Balance",
          icon: "account_balance",
          color: "green",
          canWithdraw: true,
          canDeposit: true,
          data: () => {
            return this.$store.state.currentUser.balance;
          },
        },
        {
          title: "0.00",
          desc: "Capital Balance",
          icon: "account_balance",
          color: "blue",
          canWithdraw: false,
          canDeposit: false,
          data: () => {
            return this.$store.state.investment.reduce(
              (p, c) => Number(c.principal) + p,
              0
            );
          },
        },
        {
          title: "0.00",
          desc: "Total Profit",
          icon: "attach_money",
          color: "green",
          data: () => {
            return this.$store.state.investment.reduce(
              (p, c) => Number(c.profit) + p,
              0
            );
          },
        },
        {
          title: "0.00",
          desc: "Total Deposit",
          icon: "monetization_on",
          color: "",
          data: () => {
            return this.$store.state.payment
              .filter((e) => e.status === "approved")
              .reduce((p, c) => Number(c.amount) + p, 0);
          },
        },
        {
          title: "0.00",
          desc: "Total Withdraw",
          icon: "payments",
          color: "md-accent",
          data: () => {
            return (
              this.$store.state.withdraw
                //   .filter((e) => e.status === "approved")
                .reduce((p, c) => Number(c.amount) + p, 0)
            );
          },
        },
        {
          title: "0.00",
          desc: "Total Earnings",
          icon: "attach_money",
          color: "blue",
          data: () => {
            return this.$store.state.investment.reduce(
              (p, c) => Number(c.earned) + p,
              0
            );
          },
        },
      ],

      step: 1,
      isLoading: false,
      showDialog: false,
      amount: "",
      state: this.$store.state,
      cryptoAmt: '',
      cryptoName: '',
      cryptoNamePlural: '',
      coinSeleted: 'btc',
    };
  },
  async mounted() {
    this.$store.dispatch("getUser", { token: "" });
    this.syncInvestment();
    this.isFirstTime();

  },
  computed: {
    amtFormValid() {
      return (
        (this.$v.amount.required === false ||
          this.$v.amount.between === false) &&
        this.$v.amount.$dirty &&
        this.$v.amount.$invalid === false
      );
    },
  },
  methods: {
    syncInvestment() {
      this.$store.commit("updateInvestment", this.$store.state.investment);
    },
    isFirstTime() {
      const isFirstTime = localStorage.getItem("init");
      if (!isFirstTime) {
        this.$data.showWelcome = false;
      }
    },
    noLongerFirstTime() {
      window.localStorage.setItem("init", false);
      this.$router.push("/start-plan");
    },
    gotoWithdraw() {
      this.$router.push('/withdraw')
    }, cancel() {
      this.showDialog = false;
    },

    stepOne() {
      // check if user is loggedIn
      if (!this.$store.getters.isLoggedIn) {
        return this.$router.push("login");
      }

      this.step = 1;
      this.showDialog = true;
    },
    async stepTwoA() {
      if (this.amtFormValid) {
        return false;
      }
      this.step = 3;
    },
    async stepTwoB(coin) {
      if (this.amtFormValid) {
        return false;
      }
      this.coinSeleted = coin;
      this.step = 3;
      if (coin === "eth") {
        this.cryptoName = "Ethereum";
        this.cryptoNamePlural = 'Ethereum';
        this.cryptoAmt = await this.getEthEquivalent();
      } else {
        this.cryptoName = "Bitcoin";
        this.cryptoNamePlural = 'Bitcoins'
        this.cryptoAmt = await this.getBtcEquivalent();
      }


    },
    async stepThree() {
      const url = HOST + "/payment/create";
      const payload = {
        address: "none",
        email: this.$store.state.currentUser.email,
        amount: this.amount,
        status: "pending",
      };
      this.isLoading = true;
      const req = await Http({ method: "POST", url: url, body: payload });
      this.isLoading = false;
      this.showDialog = false;

      this.$router.replace("home");

      this.showMsg(req.message);
    },
    async getBtcEquivalent() {
      const url = HOST + "/payment/toBtc/" + this.amount;

      const result = await Http({
        method: "GET",
        url,
        body: null,
      });
      if (typeof result.data !== "number") return 0;

      return result.data;
    },
  }, validations: {
    amount: {
      required,
      between,
    },
  },


};
</script>
