import { $login, register, $getUser } from "../helpers/user";
import Http from "../helpers/http";
import { HOST } from "./global";
import router from "../router";

export default {
	async login(_, { email, pwd }) {
		// POST login request
		const res = await $login({
			email,
			pwd,
		});

		if (!res.status) {
			return res;
		}

		// commit("setUser", res.data);
		return res;
	},

	async register({ commit }, credentials) {
		// POST register request
		const res = await register({
			country: "_",
			...credentials,
		});

		if (!res.status) {
			return res;
		}

		commit("setUser", res.data);
		return res;
	},

	async getUser({ commit }, { token }) {
		// POST register request
		console.log("getting user");
		const res = await $getUser("", token);

		if (!res.status) {
			return router.push("login");
		}

		commit("setUser", res.data);
		console.log("Done getting user", res.data);

		return res;
	},

	async getPayment({ commit }) {
		const payments = await Http({
			url: `${HOST}/payment/list/true`,
			method: "GET",
			body: null,
		});
		if (!payments.status) {
			return [];
		}

		commit("updatePayment", payments.data);

		return payments.data;
	},
	async getTransactions({ commit }, { email }) {
		const transactions = await Http({
			url: `${HOST}/user/transactions/${email}`,
			method: "GET",
			body: null,
		});
		if (!transactions.status) {
			return [];
		}

		commit("updateTransactions", transactions.data);

		return transactions.data;
	},

	async getInvestment({ commit }) {
		const investment = await Http({
			url: `${HOST}/investment/list/true`,
			method: "GET",
			body: null,
		});
		if (!investment.status) {
			return [];
		}
		const data = investment.data.map((e) => {
			//   e.earned= 1000;
			const canWithdraw = Number(e.earned) > 0;
			return {
				...e,
				progress: false,
				canWithdraw,
			};
		});
		commit("updateInvestment", data);

		return data;
	},

	async getWithdraw({ commit }) {
		const withdraw = await Http({
			url: `${HOST}/withdraw/list/true`,
			method: "GET",
			body: null,
		});
		if (!withdraw.status) {
			return [];
		}

		commit("updateWithdraw", withdraw.data);

		return withdraw.data;
	},

	async getConfig({ commit }) {
		const config = await Http({
			url: `${HOST}/config/`,
			method: "GET",
			body: null,
		});
		if (!config.status) {
			return {};
		}

		commit("updateConfig", config.data);

		return config.data;
	},

	async getNotification({ commit }) {
		const notifications = await Http({
			url: `${HOST}/notification/list`,
			method: "GET",
			body: null,
		});
		if (!notifications.status) {
			return [];
		}
		const data = notifications.data.map((e) => {
			e.desc = `${e.desc}`.replace("$n");
			return e;
		});
		commit("updateNotification", data);

		return data;
	},

	setTheme({ commit }, theme) {
		try {
			// if (theme) {
			//   theme = theme === "light" ? "light" : "dark";
			// } else {
			//   theme = localStorage.getItem("theme") ?? "dark";
			// }

			localStorage.setItem("theme", "light");

			document.body.classList.toggle("light");

			commit("updateTheme", theme);
		} catch (error) {
			console.log(error);
		}
		return theme;
	},
};
