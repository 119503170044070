import { render, staticRenderFns } from "./plan.vue?vue&type=template&id=2c3d231e&scoped=true&"
import script from "./plan.vue?vue&type=script&lang=js&"
export * from "./plan.vue?vue&type=script&lang=js&"
import style0 from "./plan.vue?vue&type=style&index=0&id=2c3d231e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c3d231e",
  null
  
)

export default component.exports