import { HOST } from "../store/global";
import Http from "./http";

/**
 * Add a new user
 * @param - { firstName, lastName, email, pwd, country }
 */
export async function register(credentials) {
	return await Http({
		url: `${HOST}/register`,
		method: "POST",
		body: credentials,
	});
}

/**
 * Authenticate users
 * @param - {email, pwd}
 */
export async function $login({ email, pwd }) {
	return await Http({
		method: "POST",
		url: HOST + "/login",
		body: { email, pwd },
	});
}

/**
 * Get user over http with id i.e uid, email, tel, account number
 * @param id string
 */
export async function $getUser(id = "", token = "") {
	return await Http({
		url: `${HOST}/user/get/` + (id ? `${id}` : ""),
		method: "GET",
		body: null,
		token,
	});
}
