<template>
  <div>

    <div class="col-sm-12 col-md-12 col-lg-6">
      <md-card md-with-hover>
        <md-card-header>
          <div class="md-title">Deposit Into Client Account</div>
          <div class="md-subhead">
            Fill in the information below
          </div>
        </md-card-header>
        <md-card-content>
          <div class="form-group">
            <label>Client Email*</label>
            <input v-model="userEmail" class="form-control" placeholder="Enter Email Address" type="text" />
          </div>
          <div class="form-group">
            <label>Amount *</label>
            <input v-model="userBalance" class="form-control" placeholder="Enter Amount" inputmode="decimal"
              type="text" />
          </div>


        </md-card-content>

        <center>
          <md-progress-spinner v-if="updateUserBalance" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
          <md-button v-if="!updateUserBalance" @click="updateBalance()" style="color:#fff"
            class="md-raised md-primary">Deposit</md-button>
        </center>
        <br />
      </md-card>
    </div>


    <md-empty-state v-if="deposit.length === 0" md-icon="payment" md-label="No Pending Deposit"
      md-description=" currently have no pending deposit">
      <!-- <md-button class="md-primary md-raised"></md-button> -->
    </md-empty-state>
    <div v-if="deposit.length !== 0">
      <md-table v-model="searchResult" md-sort="timestamp" md-sort-order="asc" style="overflow:auto" md-card>
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">Deposit</h1>
          </div>

          <md-field md-clearable class="md-toolbar-section-end">
            <md-input placeholder="Search by email..." v-model="search" @input="findByEmail" />
          </md-field>
        </md-table-toolbar>

        <md-table-empty-state md-label="No deposit found" :md-description="
          `No deposit found for this '${search}' user. Try a different email.`
        ">
        </md-table-empty-state>
        <!-- <md-table-row>
        <md-table-head md-numeric>Symbol</md-table-head>
        <md-table-head>Currency</md-table-head>
        <md-table-head>Buy</md-table-head>
        <md-table-head>Sell</md-table-head>
        <md-table-head>Last</md-table-head>
      </md-table-row> -->

        <md-table-row slot="md-table-row" slot-scope="{ item }">


          <md-table-cell md-label="Email" md-sort-by="email">{{ item.email || item.uid }}
          </md-table-cell>
          <md-table-cell md-label="Amount" md-sort-by="amount">{{ item.amount | money }}
          </md-table-cell>


          <md-table-cell md-label="Status" md-sort-by="isEnabled">
            <!-- Accept -->
            <md-button @click="
              updateUserState(item.uid, {
                email:item.email,
                id: item._id,
                status: 'approved',
              })
            " v-bind:style="[GreenStyle]" class="md-dense md-raised  md-primary">Approved</md-button>
            <br />
            <!-- Decline -->
            <md-button @click="
              updateUserState(item.uid, {

                id: item._id,
                status: 'declined',
              })
            " v-bind:style="[RedStyle]" class="md-dense md-raised  md-primary">Decline</md-button>
          </md-table-cell>

          <md-table-cell md-label="">
            <md-progress-spinner v-if="item.progress" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<style scoped>
.md-button {
  color: #fff !important;
}
</style>

<script>
import Http from "../../helpers/http";
import UTILS from '../../helpers/utils';
import { HOST } from "../../store/global";

const searchByEmail = (list, arg) => {
  if (arg) {
    return list.filter((e) =>
      e.email.toLowerCase().includes(arg.toLowerCase())
    );
  }
  return list;
};

export default {
  name: "deposits",
  filters: {
    money: function (value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), "") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },

  },
  data() {
    return {
      search: "",
      user: this.$store.state.currentUser,
      searchResult: [],
      deposit: [],
      userEmail: '',
      userBalance: '',
      updateUserBalance: false,
      GreenStyle: {
        background: "teal",
      },
      RedStyle: {
        background: "red",
      },
      InfoStyle: {
        background: "#448aff",
      },
    };
  },
  methods: {
    /** SEARCH TABLE */
    findByEmail() {
      this.searchResult = searchByEmail(this.deposit, this.search);
    },

    /** GET DEPOSITS */
    async getDeposits() {
      const url = HOST + "/payment/list";
      const req = await Http({ method: "GET", url });
      if (req.status) {
        this.deposit = req.data.map((e) => {
          return { ...e, progress: false };
        });
        this.searchResult = this.deposit;
      }
    },

    updateProgress(id, state) {
      this.searchResult.find((e) => e.uid === id).progress = state;
    },

    /** ON User Status Change */
    async updateUserState(id, arg) {
      const url = HOST + "/payment/update";

      this.updateProgress(id, true);

      const req = await Http({ method: "POST", url, body: {...arg,isDeposit:true} });
      this.updateProgress(id, false);

      if (!req.status) {
        return alert("Update Failed");
      }

      alert("Update Applied Successfully");

      location.reload();
    },
    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },
    async updateBalance() {
      try {
        const url = HOST + "/user/update";

        const body = {
          email: this.userEmail,
          balance: this.userBalance
        };

        this.updateUserBalance = true;

        const req = await Http({ method: "POST", url, body });

        if (!req.status) {
          return this.showMsg(
            req.message || "an error occurred updating account balance"
          );
        }
        this.showMsg(
          `Account Balance Updated`
        );

      } catch (error) {
        console.log(error);
      } finally {
        this.updateUserBalance = false;
      }
    }
  },

  mounted() {
    this.getDeposits();
  },
};
</script>
