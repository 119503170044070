import Vue from "vue";
import VueRouter from "vue-router";
import Plans from "../views/dashboard/plan.vue";
import Login from "../views/auth/login.vue";
import PasswordReset from "../views/auth/password_reset.vue";
import Register from "../views/auth/register.vue";

import Dashboard from "../views/dashboard/dashboard.vue";
import Profile from "../views/dashboard/profile.vue";
import Investment from "../views/dashboard/investment.vue";
import Deposit from "../views/dashboard/deposit.vue";
import Transfer from "../views/dashboard/transfer.vue";
import Withdraw from "../views/dashboard/withdraw.vue";
import Notification from "../views/dashboard/notification.vue";
import MainHome from "../views/home.vue";

import Admin from "../views/admin.vue";
import AdminDashboard from "../views/admin/dashboard.vue";
import AdminUsers from "../views/admin/users.vue";
import AdminInvestment from "../views/admin/investment.vue";
import AdminDeposit from "../views/admin/deposit.vue";
import AdminWithdraw from "../views/admin/withdraw.vue";
import AdminNotification from "../views/admin/notification.vue";

import { canEnterComponent, canEnterAsAdminComponent } from "./auth";

Vue.use(VueRouter);

const admin =
	/** Admin */
	{
		path: "/admin",
		name: "Admin Panel",
		component: Admin,
		beforeEnter: canEnterAsAdminComponent,
		children: [
			{
				path: "/users",
				name: "User Management",
				component: AdminUsers,
				beforeEnter: canEnterAsAdminComponent,
			},
			{
				path: "/investments",
				name: "Investment Management",
				component: AdminInvestment,
				beforeEnter: canEnterAsAdminComponent,
			},
			{
				path: "/deposits",
				name: "Deposit Management",
				component: AdminDeposit,
				beforeEnter: canEnterAsAdminComponent,
			},
			{
				path: "/notifications",
				name: "Notifications",
				component: AdminNotification,
				beforeEnter: canEnterAsAdminComponent,
			},
			{
				path: "/withdraws",
				name: "Withdraw Management",
				component: AdminWithdraw,
				beforeEnter: canEnterAsAdminComponent,
			},
			{
				path: "/config",
				name: "Configuration",
				component: AdminWithdraw,
				beforeEnter: canEnterAsAdminComponent,
			},

			{
				path: "*",
				name: "Admin",
				component: AdminDashboard,
				beforeEnter: canEnterAsAdminComponent,
			},
		],
	};

const routes = [
	{
		path: "/",
		name: "Home",
		component: Login,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
	},
	{
		path: "/password-reset",
		name: "Reset-Password",
		component: PasswordReset,
	},
	/**Backend */
	{
		path: "/home",
		name: "Home",
		component: MainHome,
		beforeEnter: canEnterComponent,
		children: [
			{
				path: "/profile",
				name: "Profile",
				component: Profile,
				beforeEnter: canEnterComponent,
			},
			{
				path: "/notification",
				name: "Notification",
				component: Notification,
				beforeEnter: canEnterComponent,
			},
			{
				path: "/investment",
				name: "Investment",
				component: Investment,
				beforeEnter: canEnterComponent,
			},
			{
				path: "/start-plan",
				name: "Plans",
				component: Plans,
				beforeEnter: canEnterComponent,
			},
			{
				path: "/deposit",
				name: "Deposit",
				component: Deposit,
				beforeEnter: canEnterComponent,
			},
			{
				path: "/transfer",
				name: "Transfer",
				component: Transfer,
				beforeEnter: canEnterComponent,
			},
			{
				path: "/withdraw",
				name: "Withdraw",
				component: Withdraw,
				beforeEnter: canEnterComponent,
			},

			{
				path: "*",
				name: "Dashboard",
				component: Dashboard,
				beforeEnter: canEnterComponent,
			},
		],
	},
	admin,
	{
		path: "*",
		name: "Welcome",
		component: Login,
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

export function resetRouter() {
	const newRouter = new VueRouter({
		mode: "history",
		routes,
	});
	router.matcher = newRouter.matcher; // the relevant part
}

export default router;
