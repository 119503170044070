export default {
	token: "",
	showMsg: "",
	currentUser: null,
	payment: [],
	transactions: [],
	notification: [],
	investment: [],
	withdraw: [],
	config: null,
	theme: "light",
};
