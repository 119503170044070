<template>
  <div class="container plan-txt">
    <!-- Section Content Starts -->
    <div v-for="plan in plans" v-bind:key="plan.name">
      <h3 class="text-center">{{ plan.name }}</h3>
      <p class="text-center"></p>
      <div class="row pricing-tables-content pricing-page">
        <div class="pricing-container">
          <!-- Pricing Tables Starts -->
          <ul class="pricing-list bounce-invert">
            <li v-for="data in plan.data" v-bind:key="data.id" class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
              <ul class="pricing-wrapper">
                <!-- Pricing Table #1 Starts -->
                <li>
                  <header class="pricing-header">
                    <h2>{{ data.name }}<span> </span></h2>
                  </header>

                  <div style="padding-left: 10%">
                    <p>Investment Amount:</p>
                    <p class="item">
                      {{ data.amount.min | money }} -
                      {{ data.amount.max | money }}
                    </p>

                    <p>Interest:</p>
                    <p class="item">
                      {{ data.interest }}
                    </p>

                    <p>Referral Bonus</p>
                    <p class="item">
                      {{ data.referal }}
                    </p>

                    <p>Company Commission</p>
                    <p class="item">
                      {{ data.companyCommission }}
                    </p>

                    <p>Profit Withdrawal</p>
                    <p class="item">
                      {{ data.profitWithdraw }}
                    </p>

                    <p>Contract period</p>
                    <p class="item">
                      {{ data.period }}
                    </p>
                  </div>

                  <footer class="pricing-footer">
                    <button @click.prevent="stepOne(data)" class="btn btn-primary">
                      Start Plan
                    </button>
                  </footer>
                </li>
                <!-- Pricing Table #1 Ends -->
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <md-dialog :md-click-outside-to-close="false" :md-fullscreen="false" :md-theme="light" :md-active.sync="showDialog"
      style="color:#000">
      <!-- STEP ONE  1 -->
      <!-- Enter Amount Card -->
      <md-card v-if="plan !== null && step === 1">
        <md-card-header>
          <div class="md-title">{{ plan.name }}</div>
        </md-card-header>

        <md-card-content>
          <p>Enter how much you want to invest in USD ($)</p>
          <!--Enter Amount -->
          <div class="form-group">
            <input v-model="$v.amount.$model" class="form-control" placeholder="Enter Amount" type="number" />
            <p v-if="amtFormValid" class="text-danger">
              valid amount between {{ plan.amount.min | money }} to
              {{ plan.amount.max | money }}
            </p>
          </div>
        </md-card-content>

        <md-card-actions>
          <!-- CANCEL -->
          <md-button @click="cancel()">Cancel</md-button>
          <!-- PROGRESS BAR -->
          <md-progress-spinner v-if="isLoading" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
          <!-- SUBMIT -->
          <md-button style="color: #fff" @click="stepTwoA" :disabled="amtFormValid"
            class="md-dense md-raised md-primary">Proceed</md-button>
        </md-card-actions>
      </md-card>

      <!-- STEP TWO-->

      <div v-if="plan !== null && step === 2" style="padding: 40px; overflow: auto">
        <md-content class="md-scrollbar">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="mdl-card__title-text mdl-typography--font-bold">
                How do you intend to deposit
              </h2>
              <p>Select your method of deposit</p>
              <br />

            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <br />

              <div style="display: flex; width:100%;  justify-content: space-around;align-content:center;">
                <img @click="stepTwoB('btc')" src='btc.png' width="100" height="100" />
                <img @click="stepTwoB('eth')" src='eth.png' width="100" height="100" />
              </div>



            </div>
          </div>
        </md-content>
      </div>

      <!-- STEP ONE  3 -->
      <!-- ACCEPT TERMS AND CONDITION  -->

      <div v-if="plan !== null && step === 3" style="padding: 20px; overflow: auto">
        <md-content class="md-scrollbar">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="mdl-card__title-text mdl-typography--font-bold">
                Total: <b>{{ cryptoAmt }}</b> {{ coinSeleted.toUpperCase() }}
              </h2>
              <br />
              <p class="">
                1. Go to any bitcoin ATM near you or go to blockchain.info and get {{ cryptoNamePlural }} ({{
                  coinSeleted.toUpperCase() }}) if you don't
                have it.
              </p>
              <p>2. Correctly scan or copy the wallet address to avoid loss transaction</p>
              <p>
                3. Send <b>{{ cryptoAmt }}</b> {{ cryptoNamePlural }} to the
                address below.
              </p>
              <p>
                You can reach our to our support team for assistance if needed
              </p>
              <p>
                <b>Send exactly <b>{{ cryptoAmt }}</b> {{ coinSeleted.toUpperCase() }} (plus miner fee) to:</b>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <br />

              <center>
                <img :src="'./wallet.jpg'" width="200" height="200" />
              </center>
              <p style="
                      color: #eb4c22;
                      text-align: center;
                      font-weight: bolder;
                      text-overflow: string;
                    ">
                {{ 'bc1q5cukj0phavdkqtmdmm447ypwvtarhhp8d9gypw' }}
              </p>

              <br />
              <br />

              <center>
                <button v-if="!isLoading" @click.prevent="stepThree()" class="btn btn-primary">
                  I have sent {{ btc }} to the above address
                </button>

                <md-progress-spinner v-if="isLoading" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
              </center>

              <center>
                <md-button v-if="!isLoading" style="margin:10px" @click.prevent="showDialog = false" class="btn">
                  Cancel
                </md-button>
              </center>
            </div>
          </div>
        </md-content>
      </div>
    </md-dialog>
  </div>
</template>

<style scoped>
.item {
  color: whitesmoke;
  font-weight: bold;
  font-size: 18px;
}
</style>

<script>
import UTILS from "../helpers/utils";
import { required } from "vuelidate/lib/validators";
import Http from "../helpers/http";
import { HOST, PLAN } from "../store/global";

const between = function (val) {
  const min = Number(`${this.plan.amount.min}`);
  const max = Number(`${this.plan.amount.max}`);
  const amt = Number(`${val}`);

  return amt > min - 1 && amt < max - 1;
};

export default {
  name: "Home",

  filters: {
    money: function (value) {
      try {
        if (Number(value) - 2) {
          return UTILS.moneyFormat(Number(value));
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
  },
  data() {
    return {
      step: 2,
      isLoading: false,
      showDialog: false,
      amount: "",
      state: this.$store.state,
      cryptoAmt: '',
      cryptoName: '',
      cryptoNamePlural: '',
      coinSeleted: 'btc',
      user: this.$store.state.currentUser,

      plan: null,
      plans: PLAN,
    };
  },
  computed: {
    amtFormValid() {
      return (
        (this.$v.amount.required === false ||
          this.$v.amount.between === false) &&
        this.$v.amount.$dirty &&
        this.$v.amount.$invalid === false
      );
    },
  },
  methods: {
    cancel() {
      this.showDialog = false;
    },

    stepOne(plan) {
      // check if user is loggedIn
      if (!this.$store.getters.isLoggedIn) {
        return this.$router.push("login");
      }
      this.plan = plan;
      this.step = 1;
      this.showDialog = true;
    },
    async stepTwoA() {
      if (this.amtFormValid) {
        return false;
      }
      this.step = 2;
    },
    async stepTwoB(coin) {
      if (this.amtFormValid) {
        return false;
      }
      this.coinSeleted = coin;
      this.step = 3;
      if (coin === "eth") {
        this.cryptoName = "Ethereum";
        this.cryptoNamePlural = 'Ethereum';
        this.cryptoAmt = await this.getEthEquivalent();
      } else {
        this.cryptoName = "Bitcoin";
        this.cryptoNamePlural = 'Bitcoins'
        this.cryptoAmt = await this.getBtcEquivalent();
      }


    },
    async stepThree() {
      const url = HOST + "/payment/create";
      const payload = {
        address: "none",
        email: this.$store.state.currentUser.email,
        amount: this.amount,
        plan: this.plan.id,
        status: "pending",
      };
      this.isLoading = true;
      const req = await Http({ method: "POST", url: url, body: payload });
      this.isLoading = false;
      this.showDialog = false;

      this.$router.replace("home");

      this.showMsg(req.message);
    },
    async getBtcEquivalent() {
      const url = HOST + "/payment/toBtc/" + this.amount;

      const result = await Http({
        method: "GET",
        url,
        body: null,
      });
      if (typeof result.data !== "number") return 0;

      return result.data;
    },
    async getEthEquivalent() {
      const url = "https://api.coinbase.com/v2/exchange-rates?currency=ETH";

      const result = await Http({
        method: "GET",
        url,
        body: null,
      });

      const usdt = Number(result?.data?.rates?.USDT ?? '0');


      if (typeof usdt !== "number") return 0;

      return Number(this.amount) / usdt;
    },
    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },
    closeMsg() {
      this.$store.commit("showMsg", "");
    },
  },
  mounted() {
    this.$store.commit("loadUser");
  },
  created() {
    this.$store.commit("loadUser");
  },
  validations: {
    amount: {
      required,
      between,
    },
  },
};
</script>
