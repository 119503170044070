<template>
  <div>
    <div class="row" style="display: flex; justify-content: center;">

      <div class="col-sm-12 col-md-12 col-lg-6">
        <form @submit.prevent="withdrawFun">

          <md-card md-with-hover>
            <md-card-header>
              <div class="md-title">Bank Withdraw</div>
              <div class="md-subhead">
                Fill in the details below to continue
              </div>
            </md-card-header>
            <md-card-content>
              <div class="form-group">
                <label>Bank Name *</label>
                <input v-model="bankAccount" required class="form-control" placeholder="Enter Bank Name" type="text" />
              </div>
              <div class="form-group">
                <label>Receiver Account Name *</label>
                <input v-model="accountName" required class="form-control" placeholder="Enter Receiver Account Name"
                  type="text" />
              </div>
              <div class="form-group">
                <label>Receiver Account No/IBAN</label>
                <input v-model="accountNo" required class="form-control" placeholder="Enter Receiver Account No/IBAN"
                  type="text" />
              </div>
              <div class="form-group">
                <label>SWIFT</label>
                <input v-model="swift"  class="form-control" placeholder="Enter SWIFT" type="text" />
              </div>
              <div class="form-group">
                <label>Transaction Description</label>
                <input v-model="desc" required class="form-control" placeholder="Enter Transaction Description"
                  type="text" />
              </div>
              <div class="form-group">
                <label>Amount</label>
                <input v-model="amount" required class="form-control" placeholder="Enter Amount" :inputmode="decimal"
                  type="text" />
              </div>

            </md-card-content>

            <center>
              <md-progress-spinner v-if="plan_progress" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
              <md-button type="submit" v-if="!plan_progress" v-bind:style="[InfoStyle]"
                class="md-raised md-primary">Proceed With
                Withdraw</md-button>
            </center>
            <br />
          </md-card>

        </form>
      </div>


    </div>
    <md-empty-state v-if="withdraw.length === 0" md-icon="library_books" md-label="No Withdraws"
      md-description="You currently have no withdraw history">
      <!-- <md-button class="md-primary md-raised"></md-button> -->
    </md-empty-state>

    <md-table v-if="withdraw.length !== 0" v-model="withdraw" md-sort="name" md-sort-order="asc" md-card>
      <md-table-toolbar>
        <h1 class="md-title">Withdraw History</h1>
      </md-table-toolbar>

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Investment" md-sort-by="investmentRef">
          {{ item.investmentRef }}</md-table-cell>
        <md-table-cell md-label="Currency" md-sort-by="name">USD
        </md-table-cell>
        <md-table-cell md-label="Amount" md-sort-by="amount">{{ item.amount | money }}
        </md-table-cell>
        <md-table-cell md-label="Status" md-sort-by="status">{{
          item.status
        }}</md-table-cell>
        <md-table-cell md-label="Date" md-sort-by="timestamp">{{
          item.timestamp | date
        }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import UTILS from "../../helpers/utils";
import { PLAN } from "../../store/global";
import Http from "../../helpers/http";
import { HOST } from "../../store/global";
export default {
  name: "Withdraw",
  filters: {
    money: function (value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), " ") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
    date: function (val) {
      return new Date(val).toLocaleDateString();
    },
    plan: function (value) {
      const plan = PLAN.find(
        (e) => e.data.filter((p) => p.id === value).length > 0
      );

      return plan.data.find((e) => e.id === value).name;
    },
  },
  data() {
    return {
      user: this.$store.state.currentUser,

      withdraw: [],
      bankAccount: '',
      accountName: '',
      accountNo: '',
      swift: '',
      desc: '',
      amount: '',
    };
  },
  methods: {
    async withdrawFun() {
      if (!this.bankAccount || !this.accountName || !this.accountNo  || !this.desc || !this.amount) {
        return;
      }


      const url = HOST + "/withdraw/create";


      const req = await Http({
        method: "POST",
        url,
        body: {
          amount: this.amount, uid: this.user.uid
        },
      });

      if (!req.status) {
        return this.showMsg(req.message || "Withdraw failed");
      }

      this.showMsg("Withdraw is under going processing");

    },
    /**
 * Show Snack Bar
 */
    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },
  },
  created() {
    this.unsubsribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "updateWithdraw") {
        this.withdraw = state.withdraw;
      }
    });
  },
  beforeDestroy() {
    this.unsubsribe();
  },
  async mounted() {
    this.$store.dispatch("getWithdraw");
    this.$store.dispatch("getUser", { token: "" });
  },
};
</script>
