<template>
  <div>

    <div class="row">
      <form @submit.prevent="updateAccount">
        <div class="row" style="display: flex; justify-content: center;">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <md-card md-with-hover>
              <md-card-header>
                <div class="md-title">Transfer</div>
                <div class="md-subhead">
                  Fill valid receipt info below
                </div>
              </md-card-header>
              <md-card-content>
                <div class="form-group">
                  <label>Email Address *</label>
                  <input v-model="to" class="form-control" placeholder="Enter Email Address" required type="email" />
                </div>
                <div class="form-group">
                  <label>Amount *</label>
                  <input v-model="amount" class="form-control" required placeholder="Enter Capital" inputmode="decimal"
                    type="text" />
                  <!-- {{ this.user.email }} ==== {{ this.to }} ==== {{ this.amount }} -->
                </div>


              </md-card-content>

              <center>
                <md-progress-spinner v-if="progress" :md-diameter="20" :md-stroke="3" md-mode="indeterminate" />
                <md-button v-if="!progress" style="color:#fff" type="submit" class="md-raised md-primary">Proceed With
                  Transfer</md-button>
              </center>
              <br />
            </md-card>
          </div>
        </div>


      </form>
    </div>
    <br />
    <br />
    <br />
    <div>
      <md-empty-state v-if="transaction.length === 0" md-icon="history" md-label="No Transaction"
        md-description="You currently have no transaction history">
      </md-empty-state>
      <!-- Market -->
      <md-table v-if="transaction.length !== 0" v-model="transaction" md-sort="name" md-sort-order="asc" md-card>
        <md-table-toolbar>
          <h1 class="md-title">Transactions</h1>
        </md-table-toolbar>

        <!-- <md-table-row>
        <md-table-head md-numeric>Symbol</md-table-head>
        <md-table-head>Currency</md-table-head>
        <md-table-head>Buy</md-table-head>
        <md-table-head>Sell</md-table-head>
        <md-table-head>Last</md-table-head>
      </md-table-row> -->

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <!-- <md-table-cell md-label="Plan" md-sort-by="plan">
          {{ item.plan | plan }}</md-table-cell
        > -->
          <!-- <md-table-cell md-label="Currency" md-sort-by="name">USD
          </md-table-cell> -->
          <md-table-cell md-label="Amount" md-sort-by="amount">${{ item.amount | money }}
          </md-table-cell>
          <md-table-cell md-label="From" md-sort-by="fromAccountName">{{
            item.fromAccountName
          }}</md-table-cell>
          <md-table-cell md-label="To" md-sort-by="toAccountName">{{
            item.toAccountName
          }}</md-table-cell>
          <md-table-cell md-label="Status" md-sort-by="status">{{
            item.status
          }}</md-table-cell>

          <md-table-cell md-label="Date" md-sort-by="date">{{
            item.timestamp
          }}</md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import { isValidEmail } from '../../helpers/regex';
import UTILS from "../../helpers/utils";
import { PLAN, HOST } from "../../store/global";
import Http from "../../helpers/http";
import router from '../../router';

export default {
  name: "transfer",
  filters: {
    money: function (value) {
      try {
        if (Number(value) - 2) {
          return `${UTILS.moneyFormat(Number(value), " ") ?? ""}`.trim();
        }
        return UTILS.moneyFormat(0);
      } catch (error) {
        console.log(error);
        return UTILS.moneyFormat(0);
      }
    },
    plan: function (value) {
      const plan = PLAN.find(
        (e) => e.data.filter((p) => p.id === value).length > 0
      );

      return plan.data.find((e) => e.id === value).name;
    },
  },
  data() {
    return {
      transaction: this.$store.state.transactions,
      user: this.$store.state.currentUser,
      amount: '',
      to: '',
    };
  },
  methods: {
    // showError() {
    //   this.showMsg("Unable to process transfer at the moment");
    // },
    async updateAccount() {
      try {
        const to = this.to;
        const from = this.user.email;
        const amount = this.amount;

        if (!this.to || !isValidEmail.test(to)) {
          return this.showMsg("Valid email address is required");
        }

        if (!amount || Number.isNaN(Number(amount)) || Number(amount) < 0) {
          return this.showMsg("Valid amount is required");
        }



        const url = HOST + "/user/transfer";

        const body = {
          "from": from,
          "to": to,
          "amount": amount + ''
        };

        this.updateProgress = true;

        const req = await Http({ method: "POST", url, body });

        if (!req.status) {
          return this.showMsg(
            req.message || "an error occurred transferring account"
          );
        }
        this.$store.dispatch("getUser", { token: "" });
        this.$store.dispatch("getUser", { token: "" });
        setTimeout(() => {
          this.showMsg("Transfer Successful");
        }, 500);
        setTimeout(() => {
          router.push("/home")
        }, 1500);
        // this.$store.commit("setUser", req.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.updateProgress = false;
      }
    },
    showMsg(msg) {
      return this.$store.commit("showMsg", msg);
    },
  },

  async mounted() {
    this.$store.dispatch("getTransactions", { email: this.user.email });
  },
};
</script>
